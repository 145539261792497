@keyframes move-shadow-ios {
  0% {
    box-shadow: 0px 1px 2px 1px rgba(3, 59, 78, 0.3); /* Light Blue */
  }
  20% {
    box-shadow: 2px 0px 4px 1px rgba(50, 1, 155, 0.3); /* Sky Blue */
  }
  40% {
    box-shadow: 2px 5px 5px 1px rgba(6, 29, 73, 0.3); /* Cornflower Blue */
  }
  60% {
    box-shadow: 1px 5px 5px 1px rgba(36, 69, 95, 0.3); /* Steel Blue */
  }
  80% {
    box-shadow: 1px 0px 4px 1px rgba(14, 87, 159, 0.3); /* Dodger Blue */
  }
  100% {
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 255, 0.3); /* Blue */
  }
}

@keyframes move-shadow-android {
  0% {
    box-shadow: 0px 0px 5px 1px rgba(144, 238, 144, 0.3); /* Light Green */
  }
  20% {
    box-shadow: 5px 0px 5px 1px rgba(60, 179, 113, 0.3); /* Medium Sea Green */
  }
  40% {
    box-shadow: 5px 5px 5px 1px rgba(46, 139, 87, 0.3); /* Sea Green */
  }
  60% {
    box-shadow: 0px 5px 5px 1px rgba(34, 139, 34, 0.3); /* Forest Green */
  }
  80% {
    box-shadow: 0px 0px 5px 1px rgba(0, 128, 0, 0.3); /* Green */
  }
  100% {
    box-shadow: 0px 0px 5px 1px rgba(0, 100, 0, 0.3); /* Dark Green */
  }
}

.animate-move-shadow-ios {
  animation: move-shadow-ios 10s infinite;
}

.animate-move-shadow-android {
  animation: move-shadow-android 10s infinite;
}



/* ---------------------------------------------- */



@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  25% {
    transform: translateX(-15%);
  }
  50% {
    transform: translateX(0%);
  }
  75% {
    transform: translateX(15%);
  }
  100% {
    transform: translateX(0%);
  }
}

.animate-scroll {
  display: flex; /* Ensure children are in a row */
  animation: scroll 20s linear infinite;
}


