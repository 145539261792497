@tailwind base;
@tailwind components;
@tailwind utilities;




*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: black;
}





